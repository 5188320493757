import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | AT&T Center",
    pageDescription:
      "Learn how a professional sports arena increased revenue with SpotOn by streamlining operations and giving fans more ways to order and pay.",
    title: "Scoring more sales during events",
    subTitle: `How the AT&T Center and San Antonio Stock Show & Rodeo boost sales by giving fans more ways to order.`,
    businessName: "AT&T Center",
    industry: "Enterprise, sports & entertainment",
    location: "San Antonio, Texas",
    products: [
      spotonProducts.ENTERPRISE_POS,
      spotonProducts.SELF_SERVICE_KIOSKS,
      spotonProducts.HANDHELDS,
      spotonProducts.KITCHEN_DISPLAYS,
      spotonProducts.VENDING_INVENTORY,
    ],
    description:
      "The AT&T Center (18,500+ seating capacity) hosts the San Antonio Spurs (NBA), the Stock Show Rodeo, and multiple concerts throughout the year. With SpotOn, the center has increased revenue by making it easier for fans to order during games and events.",
    quoteData: {
      imgName: "at-t-center.png",
      quote: `“We’ve experienced positive results at our kiosks which use SpotOn POS technology. Those sites are averaging double-digit increases in both units per transaction and average check size compared to traditional belly-up service.”`,
      personName: "Kevin Barker",
      personTitle: "Senior Manager Culinary Experience, Spurs Sports & Entertainment",
    },
    goal: `In 2018, the AT&T Center was looking to upgrade its legacy POS system due to issues with hardware, reporting, network connections, and payment processing. They needed a zero-downtime solution with more ordering options for spectators.`,
    solution: `SpotOn's mix of software and hardware gave the center a cloud-based platform with a seamless offline mode for continual uptime. After deployment, revenue increased dramatically with the new 370+ POS terminals, self-service kiosks, handhelds, and kitchen display systems.`,
    results: {
      title: `The results`,
      stats: [
        { title: `18%`, description: `increase in average order size at NBA games` },
        {
          title: `$3.15`,
          description: `more per check than the nearest POS at NBA games`,
        },
        { title: `370+`, description: `terminals, kiosks, handhelds, and KDS deployed` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
      title: "A state of the art garden",
        imageName: "td-garden.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-31",
        href: "/case-studies/td-garden",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
      title: "A POS system that knocks it out of the park",
        imageName: "durham-bulls.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-31",
        href: "/case-studies/durham-bulls",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
    {
      title: "Helping fans see more touchdowns",
        imageName: "louisiana-state-university.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-10",
        href: "/case-studies/louisiana-state-university",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
  ];
  